<template>
<span>
    <vs-button  @click="DownloadDocument()" :size="Size" :color="Color" :type="buttontype" :class="ButtonClass" :icon="icon"></vs-button>
    <vs-prompt
      :buttons-hidden="true"
      :active.sync="dlgActive"
      :title="$t('MESSAGES.SEND_DOCUMENT_MAIL.TITLE_NO_DOC')"
      id="promptDownloadDlg">
       <div>
          <vs-alert
            active.sync="true"
            close-icon="clear"
            :title="$t('MESSAGES.SEND_DOCUMENT_MAIL.ALERT_TITLE_NO_DOC')"
            icon ="warning" class="mb-2">
            <vs-progress indeterminate class="mt-3 mb-3" color="primary"></vs-progress>
            <p>{{$t('MESSAGES.SEND_DOCUMENT_MAIL.ALERT_TEXT_NO_DOC')}}</p>
            
              <vs-button v-if="!printStarted" icon="print" @click="PrintDoc()"  class="mb-2">{{$t('MESSAGES.SEND_DOCUMENT_MAIL.PRINT')}}</vs-button>
              <vs-button v-if="printStarted" color="gray" icon="refresh" @click="DownloadDocument()"  class="mb-2">{{$t('MESSAGES.SEND_DOCUMENT_MAIL.RELOAD')}}</vs-button>
            </vs-alert>
       </div>
     </vs-prompt>
</span>
</template>
<script>
import codeHelper from '../../helper/staticCodeHelper';
import downloadMethods from '../../helper/staticDownloadFuncHelper';

export default {
  name: "PollingButtonDoc",
  props:['DocumentType','LinkedFk','Icon','Size','Color','Type','ButtonClass', 'InitDocId'],
   data: function (){
     return {
       icon:this.Icon,
       buttontype:this.Type,
       actDocId:this.InitDocId,
       dlgActive:false,
       printStarted:false
     }
  },
  
  created () {
    if(this.$appConfig.signalRDisabled == false)
    {
      // Listen to score changes coming from SignalR events
      this.$sharedHub.$on('documentready', this.DocumentReady);
    }
  },
  beforeDestroy () {
    if(this.$appConfig.signalRDisabled == false)
    {
      // Make sure to cleanup SignalR event handlers when removing the component
      this.$sharedHub.$off('documentready', this.DocumentReady);
    }
  },
  mounted(){

  },
  computed:{

  },
  methods:{
    ...codeHelper,
    ...downloadMethods,
    DocumentReady(){
      if(this.dlgActive && this.printStarted == true)
      {
        this.DownloadDocument();
      }
    },
    DownloadDocument()
    {
      this.$store.dispatch('document/downloadDocumentByLinkedFk', {linkedFk:this.LinkedFk, type: this.DocumentType})
          .then(response => {

              if(response.data != '' && response.name != '')
              {
                  this.DownloadWithFileSaver(response);
                  this.dlgActive = false;
              }
              else
              {
                this.dlgActive = true;
                
                if(this.printStarted == false)
                {
                  this.PrintDoc();
                }
              }
            }
          );
    }, 
    PrintDoc(){
        this.$store.dispatch('document/createDocument', {id:this.LinkedFk,name:"", type: this.DocumentType})
              .then(success => {
                if(success === true)
                {
                  this.printStarted = true;
                  this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('INVOICE.SUCCESS.PRINT').replace("*1*",name)});
                }
                else
                {
                  this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('INVOICE.ERROR.PRINT').replace("*1*",name)});
                }
              }, 
              error =>{
                this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('INVOICE.ERROR.PRINT').replace("*1*",name)+' '+error});
              }); 
      },
  },
  watch:{

  }
};
</script>
<style>

</style>