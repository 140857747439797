<template>
    <div>
        <vs-button 
            size="small"
            type="border"
            icon="expand_more" 
            @click="popupActive=true"
            :disabled="disabled"><span v-show="showColorText" >{{selectedRal.ral +' ' +this.$t('RALCOLOR.'+selectedRal.ral.replace(' ','_'))}} </span>
                        <vs-icon icon="fiber_manual_record"  :style="'color:'+selectedRal.hex+';'" ></vs-icon>
            
        </vs-button>
        <!-- z-index: mutter dlg hat 30000 - also muss dieser höher sein -->
        <vs-prompt
        @cancel="Canceled"
        @close="Canceled"
        @accept="Accepted"
        :title="$t('MOTIFPICKER.TTITLE')"
        :accept-text="$t('COM.SELECT')"
        :cancel-text="$t('COM.CANCEL')"
        id="promptMotifpicker"
        style="z-index:30002"
        :active.sync="popupActive">

            <div style="max-width:1000px">
                
                <div v-show="selectedRalId != 0" class="float-left modelx bg-light ml-1 p-2 mb-1 font-weight-bold align-items-center border">
                    <span class="mr-2">{{$t('RALCOLOR.ACT_COL')}}</span>
                    <vs-icon icon="fiber_manual_record"  :style="'color:'+selectedRal.hex+';'" ></vs-icon>
                    <span>{{selectedRal.ral +' ' +this.$t('RALCOLOR.'+selectedRal.ral.replace(' ','_'))}} </span>
                </div> 
                <vs-divider icon="palette" position="center"></vs-divider>
                <vs-tabs >
                    <vs-tab :label="$t('RALCOLOR.AVAILABLE')">
                        <div class="clearfix" >
                            <template  v-for="(color, index) in stdRalColors">                   
                                <div :key="'ralcardcol_'+index"  class="float-left modelx  bg-light ml-1 p-2 mb-1 font-weight-bold d-flex align-items-center border cursor-pointer" @click="selectedRalId=color.id">
                                    <vs-icon icon="fiber_manual_record"  :style="'color:'+color.hex+';'" ></vs-icon>
                                    <span>{{color.ral}}</span>
                                </div>                          
                            </template>
                        </div>
                    </vs-tab>
                    <template v-for="(nr,idx) in startnumbers">
                        <vs-tab :key="idx+'_ralcardcolnr'" :label="'RAL ' +nr+'XXX'">     
                            <div class="clearfix" >
                                <template  v-for="(color, index) in getRalColorsByNr(nr)">                   
                                    <div :key="'ralcardcol_'+index"  class="float-left modelx  bg-light ml-1 p-2 mb-1 font-weight-bold d-flex align-items-center border cursor-pointer" @click="selectedRalId=color.id">
                                        <vs-icon icon="fiber_manual_record"  :style="'color:'+color.hex+';'" ></vs-icon>
                                        <span>{{color.ral}}</span>
                                    </div>                          
                                </template>
                            </div>
                        </vs-tab> 
                    </template>      
                </vs-tabs>
            </div>


        </vs-prompt>
    </div>
</template>

<script>
import helperMethods from '../../helper/staticFuncHelper';
import codeHelper from '../../helper/staticCodeHelper';

export default {
  name: "RalColorPicker",
     components: {     
  },
  props:['value','disabled','showColorText','label' ],
  data: function (){
     return {
       allRalColors:[],
       stdRalColors:[],
       selectedRal:this.InitColor(this.value),
       selectedRalId:this.InitColor(this.value).id,
       selectedRalOld:this.InitColor(this.value),
       selectedRalIdOld:this.InitColor(this.value).id,
       popupActive:false
     }
  },
  created(){
    this.allRalColors = this.ralColors;
    this.stdRalColors = this.ralColorsStd;
  },
  computed:{
      ralColors(){
        var retVal = [];
        if(typeof(this.$store.state.ralcolor) != 'undefined')
        {
            if(this.$store.state.ralcolor.ralcolors.data != null)
            {
                var ralCol = this.$store.state.ralcolor.ralcolors.data;
                for(var i = 0; i < ralCol.length; i++)
                {
                    if(ralCol[i].available == false)
                    {
                        retVal.push(ralCol[i]);
                    }
                }
            }
        }
        return retVal;
      },
      ralColorsStd(){
        var retVal = [];
        if(typeof(this.$store.state.ralcolor) != 'undefined')
        {
            if(this.$store.state.ralcolor.ralcolors.data != null)
            {
                var ralCol = this.$store.state.ralcolor.ralcolors.data;
                for(var i = 0; i < ralCol.length; i++)
                {
                    if(ralCol[i].available == true)
                    {
                        retVal.push(ralCol[i]);
                    }
                }
            }
        }
        return retVal;
      },
      startnumbers(){
        var retVal = [];

        for(var i = 0; i < this.allRalColors.length; i++)
        {
            var tmp = this.allRalColors[i].ral.replace("RAL ","");
            tmp = tmp.substring(0,1);
            if(!retVal.includes(Number(tmp)))
            {
                retVal.push(Number(tmp));
            }
        }
        return retVal;
      },
      isLabelOkay(){
          var okay = false;
          if(this.IsNotNull(this.label))
          {
            if(this.label.length > 0)
            {
                okay = true;
            }
          }
          return okay;
      }
  },
  watch:{
    ralColors(value)
    {
        this.allRalColors = value;
    },
    selectedRalId(value){
        this.$emit('input', value);
        this.selectedRal = this.GetRalById(value);
    },
    ralColorsStd(value)
    {
        this.stdRalColors = value;
    },
    disabled(value){
        if(value == true)
        {
            this.selectedRal = this.InitColor(0);
            this.selectedRalId = this.selectedRal.id;
        }
    }
  },
  methods:{
    ...helperMethods,
    ...codeHelper,
    getRalColorsByNr(nr)
    {
        var retVal = [];

        for(var i = 0; i < this.allRalColors.length; i++)
        {
            if(this.allRalColors[i].ral.startsWith("RAL "+nr))
            {
                retVal.push(this.allRalColors[i]);
            }
        }
        return retVal;
    },
    getRalStartnumbers(){
        var retVal = [];

        for(var i = 0; i < this.allRalColors.length; i++)
        {
            var tmp = this.allRalColors[i].ral.replace("RAL ","");
            tmp = tmp.substring(0,1);
            if(!retVal.includes(Number(tmp)))
            {
                retVal.push(Number(tmp));
            }
        }
        return retVal;
    },
    InitColor(selected){
        var color = selected;
        if(color <= 0)
        {
            color = this.GetRalDefault().id;
        }
        return this.GetRalById(color);
    },
    Canceled(){
        this.selectedRalId = this.selectedRalIdOld;
    },
    Accepted(){
        this.$emit("Accepted");
    },
  }
};
</script>