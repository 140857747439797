<template>
    <div>
        <vs-button size="small" icon="expand_more" @click="popupActive=true" :disabled="disabled">{{GetMotifText(selectedMotif.motif)}}<span v-show="orientations.length > 0"> ({{GetOrientationText(selected.motif_orientation)}})</span></vs-button>
    <!-- z-index: mutter dlg hat 30000 - also muss dieser höher sein -->
        <vs-prompt
        @cancel="Canceled"
        @close="Canceled"
        @accept="Accepted"
        :title="$t('MOTIFPICKER.TTITLE')"
        :accept-text="$t('COM.SELECT')"
        :cancel-text="$t('COM.CANCEL')"
        id="promptMotifpicker"
        style="z-index:30002"
        :active.sync="popupActive">
            <div style="max-width:1000px">
                <vs-tabs>
                    <vs-tab :label="$t('MOTIFPICKER.SELECTED_MOTIF')">
                        <div class="default-input d-flex align-items-center mb-3">

                            <vs-card class="cardx ml-3 mr-3">
                                <div slot="header">
                                <h3>
                                    {{$t('MOTIFPICKER.SELECTED_MOTIF')}}: {{GetMotifText(selectedMotif.motif)}}
                                </h3>
                                </div>
                                <div slot="media">
                                    <img v-show="ShowImgPrev" v-bind:src="'data:image/jpeg;base64,'+ImgPrevData" />
                                </div>
                                <div>
                                    <div class="default-input d-flex align-items-center mb-3">
                                        <vs-select autocomplete :label="$t('MOTIFPICKER.SELECTED')" class="" v-model="selectedMotfiFkDropDown">
                                            <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in allMotifs" />
                                        </vs-select>
                                        <vs-select v-show="orientations.length > 0" :label="$t('MOTIFPICKER.SELECTED_ORI')" class="ml-3 " v-model="selected.motif_orientation">
                                            <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in orientations" />
                                        </vs-select>
                                        
                                    </div>
                                </div>
                            </vs-card>
                        </div>
                    </vs-tab>
                    <vs-tab :label="this.$t('MOTIFPICKER.STANDARD')">

                        <vs-tabs >
                            <template v-for="(item, index) in notPayable" >
                                <vs-tab :key="'prodcat'+index" :label="item.category.name">
                                    <div id="motifpicker_table" class="vs-con-loading__container" >
                                        <vs-table
                                            search
                                            :data="item.motifs"
                                            :noDataText="$t('MOTIFPICKER.NO_DATA')"
                                            stripe
                                            max-items="5" 
                                            pagination
                                            hoverFlat
                                            v-model="selectedMotif">


                                            <template slot="thead">                               
                                            <vs-th sort-key="name">
                                                {{$t('MOTIFPICKER.TABLE.COL1')}}
                                            </vs-th> 
                                            <vs-th sort-key="horizontal">
                                                {{$t('MOTIFPICKER.TABLE.COL2')}}
                                            </vs-th> 
                                            <vs-th sort-key="vertical">
                                                {{$t('MOTIFPICKER.TABLE.COL3')}}
                                            </vs-th> 
                                            <vs-th sort-key="vertical">
                                                {{$t('MOTIFPICKER.TABLE.COL4')}}
                                            </vs-th>                                                                                                                                                                                             
                                            </template>

                                            <template slot-scope="{data}">
                                            <vs-tr :data="tr"  :key="'table_cat1_'+indextr" v-for="(tr, indextr) in data" :state="selected.motif_fk == tr.id?'primary':null">                    
                                                <vs-td :data="tr.motif.name">
                                                    {{tr.motif.name}}
                                                </vs-td> 
                                                <vs-td :data="tr.horizontal">
                                                    <vs-checkbox v-model="tr.horizontal" :disabled="true"></vs-checkbox>
                                                </vs-td>    
                                                <vs-td :data="tr.vertical">
                                                    <vs-checkbox v-model="tr.vertical" :disabled="true"></vs-checkbox>
                                                </vs-td>
                                                <vs-td :data="tr.motif.img_preview_data">
                                                    <img style="max-height:50px" v-bind:src="'data:image/jpeg;base64,'+tr.motif.img_preview_data" />
                                                </vs-td>  
                                                                                                                                                                                                                                                                                                                    
                                            </vs-tr>
                                            </template>
                                        </vs-table>
                                    </div> 
                                </vs-tab>
                            </template>                    
                        </vs-tabs>    
                    </vs-tab>
                    <vs-tab :label="this.$t('MOTIFPICKER.AUFPREIS')">
                        <vs-tabs >
                            <template v-for="(item, index) in payable" >
                                <vs-tab :key="'prodcat'+index" :label="item.category.name">
                                    <div id="motifpicker_table2" class="vs-con-loading__container" >
                                        <vs-table
                                            search
                                            :data="item.motifs"
                                            :noDataText="$t('MOTIFPICKER.NO_DATA')"
                                            stripe
                                            max-items="5" 
                                            pagination
                                            hoverFlat
                                            v-model="selectedMotif">


                                            <template slot="thead">                               
                                            <vs-th sort-key="name">
                                                {{$t('MOTIFPICKER.TABLE.COL1')}}
                                            </vs-th> 
                                            <vs-th sort-key="horizontal">
                                                {{$t('MOTIFPICKER.TABLE.COL2')}}
                                            </vs-th> 
                                            <vs-th sort-key="vertical">
                                                {{$t('MOTIFPICKER.TABLE.COL3')}}
                                            </vs-th> 
                                            <vs-th sort-key="vertical">
                                                {{$t('MOTIFPICKER.TABLE.COL4')}}
                                            </vs-th>                                                                                                                                                                                             
                                            </template>

                                            <template slot-scope="{data}">
                                            <vs-tr :data="tr"  :key="'table_cat2_'+indextr" v-for="(tr, indextr) in data" :state="selected.motif_fk == tr.id?'primary':null">                    
                                                <vs-td :data="tr.motif.name">
                                                    {{GetMotifText(tr.motif)}}
                                                </vs-td> 
                                                <vs-td :data="tr.horizontal">
                                                    <vs-checkbox v-model="tr.horizontal" :disabled="true"></vs-checkbox>
                                                </vs-td>    
                                                <vs-td :data="tr.vertical">
                                                    <vs-checkbox v-model="tr.vertical" :disabled="true"></vs-checkbox>
                                                </vs-td>
                                                <vs-td :data="tr.motif.img_preview_data">
                                                    <img style="max-height:50px" v-bind:src="'data:image/jpeg;base64,'+tr.motif.img_preview_data" />
                                                </vs-td>  
                                                                                                                                                                                                                                                                                                                    
                                            </vs-tr>
                                            </template>
                                        </vs-table>
                                    </div> 
                                </vs-tab>
                            </template>                    
                        </vs-tabs>   
                    </vs-tab>
                </vs-tabs>                
            </div>
        </vs-prompt>
    </div>
</template>

<script>
import helperMethods from '../../helper/staticFuncHelper';
import codeHelper from '../../helper/staticCodeHelper';
import enumHelper from '../../helper/enumHelper';
import {orientation} from '../../helper/enumHelper';

export default {
  name: "MotifPicker",
     components: {     
  },
  props:['value','disabled','label','isLabelOkay', 'product_fk', 'currencyKurzel', 'countryLanguageFk' ],
  data: function (){
     return {
       allData:[{category:{},motifs:[]}],
       selected:this.value,
       selectedMotif:{},
       popupActive:false,
       orientations:[],
       allMotifs:[],
       selectedMotfiFkDropDown:0,
       oldMotif_fk:0
     }
  },
  mounted(){
    
    this.$store.dispatch('motif/getMotifCategories')
          .then(response => {
                if(this.IsNotNull(response))
                {
                    if(response.length > 0)
                    {
                        this.$store.dispatch('motif/getMotifs')
                                  .then(response => {
                            if(this.IsNotNull(response))
                            {
                                if(response.length > 0)
                                {
                                    this.SetSelectedMotif(this.selected.motif_fk);
                                    this.oldMotif_fk = this.selected.motif_fk;
                                }
                            }
                        }, 
                        // eslint-disable-next-line no-unused-vars
                        error =>{
                            this.SetSelectedMotif(-1);
                        });
                    
                    }
                }
              }, 
              // eslint-disable-next-line no-unused-vars
              error =>{
                this.SetSelectedMotif(-1);
              });

              
  },
  computed:{
    ShowImgPrev(){
        var retVal = false;
        if(this.IsNotNull(this.selectedMotif))
        {
            if(!this.IsEmpty(this.selectedMotif))
            {
                if(this.IsDefined(this.selectedMotif.motif.img_preview_data))
                {
                    retVal = this.selectedMotif.motif.img_preview_data.length > 0;
                }
            }
        }
        return retVal;
    },
    ImgPrevData(){
        var retVal = "";
        if(this.IsNotNull(this.selectedMotif))
        {
            if(!this.IsEmpty(this.selectedMotif))
            {
                if(this.IsDefined(this.selectedMotif.motif.img_preview_data))
                {
                    retVal = this.selectedMotif.motif.img_preview_data;
                }
            }
        }
        return retVal;
    },
    motifsByCategory(){
        var retVal = [];
        if(typeof(this.$store.state.motif) != 'undefined')
        {
            if(this.$store.state.motif.motifcategories.data != null)
            {
                var categories = this.$store.state.motif.motifcategories.data;
                categories.forEach(cat => {
                    var item = {category:cat,motifs:[],topay:false};
                    cat.member.forEach(mem => {
                        var motif = this.GetMotifById(mem.motif_fk,true);
                        if(this.IsNotNull(motif))
                        {
                            for (let index = 0; index < motif.orientationMember.length; index++) {
                                if(motif.orientationMember[index].product_fk == this.product_fk)
                                {
                                    item.motifs.push({motif:motif,vertical:motif.orientationMember[index].vertical,horizontal:motif.orientationMember[index].horizontal});
                                    if(motif.netto_from_product_fk > 0)
                                    {
                                        item.topay = true;
                                    }
                                    
                                    break;
                                }
                            }
                        }
                    });
                    retVal.push(item);
                });
            }
        }
        return retVal;
    },
    notPayable(){
        var retVal = [];
        this.allData.forEach(element => {
            if(element.topay == false)
            {
                retVal.push(element);
            }
        });
        return retVal;
    },
    payable(){
        var retVal = [];
        this.allData.forEach(element => {
            if(element.topay == true)
            {
                retVal.push(element);
            }
        });
        return retVal;
    }
  },
  watch:{
    motifsByCategory(value)
    {
        this.allData = value;
        this.allMotifs=[];
        var loaded = [];
        value.forEach(cat => {
            cat.motifs.forEach(motif => {
                if(!loaded.includes(motif.motif.id))
                {
                    this.allMotifs.push({"text": motif.motif.name, "value":motif.motif.id, "orderNr":motif.motif.orderNr});    
                    loaded.push(motif.motif.id);
                }
            });
        });

        this.allMotifs.sort(function(a, b) {
                var keyA = new Date(a.orderNr),
                    keyB = new Date(b.orderNr);
    
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
                });
    },
    selected(value)
    {
        this.$emit('input', value);
    },
    selectedMotif(value)
    {
        if(this.IsNotNull(value))
        {
            this.selected.motif_fk = value.motif.id;
            this.selectedMotfiFkDropDown = value.motif.id;
            this.orientations = [];       
            this.selected.motif_netto = value.motif.netto;
            
            if(this.IsNotNull(value.motif.orientationMember))
            {
                for (let i = 0; i < value.motif.orientationMember.length; i++) {
                    if(value.motif.orientationMember[i].product_fk == this.product_fk)
                    {
                        if(value.motif.orientationMember[i].vertical)
                        {
                            this.orientations.push({"text": this.GetOrientationText(orientation.VERTICAL), "value":orientation.VERTICAL});
                        }
                        if(value.motif.orientationMember[i].horizontal)
                        {
                            this.orientations.push({"text": this.GetOrientationText(orientation.HORIZONTAL), "value":orientation.HORIZONTAL});
                        }

                        var found = false;
                        this.orientations.forEach(ori => {
                            if(this.selected.motif_orientation == ori.value)
                            {
                                found = true;
                            }
                        });
                        if(found == false)
                        {
                            this.selected.motif_orientation = this.orientations[0].value;
                        }
                        break;
                    }
                    
                }
            }
        }
    },
    selectedMotfiFkDropDown(value){
        this.SetSelectedMotif(value);
    },
    disabled(value){
        if(value == true)
        {
            this.SetSelectedMotif(0);
        }
    }

  },
  methods:{
    ...helperMethods,
    ...codeHelper,
    ...enumHelper,
    Canceled(){
        this.SetSelectedMotif(this.oldMotif_fk);
    },
    Accepted(){
        this.$emit("Accepted");
    },
    SetSelectedMotif(id)
    {
        this.selectedMotif = {motif:{name:this.$t('MOTIFPICKER.NO_MOTIV'),id:0,netto:0,img_preview_data:""},vertical:true,horizontal:true};
        for(var i = 0; i < this.allData.length; i++)
        {
            for(var k = 0; k < this.allData[i].motifs.length; k++)
            {
                if(this.allData[i].motifs[k].motif.id == id)
                {
                    this.selectedMotif = this.allData[i].motifs[k];
                    break;
                }
            }
            
        }
    },
    GetMotifText(motif){
        var retVal = "";
        if(this.IsNotNull(motif) && !this.IsEmpty(motif))
        {
            retVal = motif.name;
            if(motif.netto > 0)
            {
                retVal += ' (+ '+this.DecimalToString(motif.netto,this.currencyKurzel,this.GetLocalFormatOfLanguage(this.countryLanguageFk))+')';
            }
        }
        return retVal;
    },
    SelectEigenmotif(){
        var eigen = this.GetEigenmotif();
        if(this.IsNotNull(eigen))
        {
            this.SetSelectedMotif(eigen.id);
        }
    }
  }
};
</script>
<style>
div#promptMotifpicker > div.vs-dialog{
  max-width: 700px !important;
}
</style>